@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .checkbox-wrapper {
    @apply flex items-center cursor-pointer;
  }

  .custom-checkbox-replacement {
    @apply w-6 h-6 border-gray-400 bg-transparent border rounded-md flex justify-center items-center mr-2 relative;
    -webkit-tap-highlight-color: transparent;
  }

  .custom-checkbox:checked + .custom-checkbox-replacement {
    @apply bg-brand-primary border-transparent;
  }

  .custom-checkbox:checked + .custom-checkbox-replacement::before {
    content: '✓';
    @apply text-white text-xl flex justify-center items-center;
  }
}
