@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-input-regular {
    @apply text-font-base text-lg bg-white rounded-full px-[33px] py-[15px] outline-none w-full text-center placeholder-font-secondary-darker focus:placeholder-opacity-30;
  }
  .form-input-bright {
    @apply text-black text-base md:text-lg bg-white rounded-full h-[50px] pr-[33px] pl-[33px] py-[14px] outline-none text-center w-full placeholder-black focus:placeholder-opacity-30;

    &--with-icon {
      @apply w-full pl-[33px] pr-[40px];
    }
  }
  .modal-input {
    @apply py-[19px] rounded-full border-[#AAAAAA80] text-base md:text-lg bg-white font-normal w-full outline-none text-center placeholder-font-secondary-darker focus:placeholder-opacity-30;
  }
}
