
// You can modify types of toast here
.toast-success {
  background-color: #28A745 !important;
}
.toast-error {
  background-color: #E30404 !important;
}
.toast-info {
  background-color: #1A77EA !important;
}
.toast-warning {
  background-color: #FFC107 !important;
}
