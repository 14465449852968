
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .icon-sidebar {
    @apply flex fill-gray-400 bg-white w-10 h-10 drop-shadow-md rounded-md group-hover:fill-black;
  }
}
