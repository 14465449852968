
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .primary-button {
    @apply bg-brand-primary px-[24px] py-2 text-white rounded-full text-lg font-bold disabled:bg-opacity-50 disabled:cursor-not-allowed;
  }
  .outlined-button {
    @apply bg-transparent px-[23px] py-2 text-white border-2 border-white rounded-full text-lg font-bold hover:text-brand-primary hover:bg-white;
  }
  .outlined-reverse-button {
    @apply bg-white px-[23px] py-2 text-brand-primary border-2 border-none rounded-full text-lg font-bold hover:text-white hover:bg-brand-primary;
  }
  .modal-cancel-button {
    @apply bg-white px-[43px] py-[16px] border-brand-red border-2 rounded-full text-brand-red text-lg font-bold disabled:bg-opacity-50 disabled:cursor-not-allowed;
  }
  .modal-proceed-button {
    @apply bg-brand-primary px-[45px] py-[18px] rounded-full uppercase text-white text-lg font-bold disabled:bg-opacity-50 disabled:cursor-not-allowed;
  }
}
