
// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@layer components {
  .base-bg {
    @apply flex flex-col p-8 bg-white rounded-plate shadow-md;
  }
  .blue {
    @apply bg-[radial-gradient(circle,_#0B42AC,_#062156)];
  }
  .red {
    @apply bg-[radial-gradient(circle,_#D1232A,_#950b15)];
  }

  .default-meter-bg {
    @apply bg-[url("/assets/images/login-page-meter.svg")];
  }
  .alarm-meter-bg {
    @apply bg-[url("/assets/images/meter-alert.svg")];
  }
}
